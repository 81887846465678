import React, {useContext, useEffect, useState} from "react";
import {WorkflowRow} from "../../Domain/Workflow/Workflow";
import {PackSizeFields, packSizeGridStyle} from "./WorkflowCommonComponents";
import {Colors} from "../../Colors";
import {DangerButton, SaveButton} from "../../Components/Buttons";
import {WorkflowContext} from "../../Contexts/WorkflowContext";

export function PackSizeEntrySidebar() {
    const {workflow, updateWorkflow, stageLevel} = useContext(WorkflowContext);
    const [validPackSize, setValidPackSize] = useState(false);
    const [currentRow, setCurrentRow] = useState<WorkflowRow>();
    const [rowCount, setRowCount] = useState(0);
    const [isSaving, setIsSaving] = useState(false);

    const saveWorkflow = () => {
        if (!isSaving) {
            setIsSaving(true);
            _updateWorkflow()
                .then(x => setIsSaving(false));
        }
    }
    
    const _updateWorkflow = async () => {
        setValidPackSize(false);
        
        if (rowCount <= 1) {
            workflow!.stage = "Approved";
            workflow!.stageReason = `Workflow approved from PackSize entry`;
        } else {
            workflow!.stageReason = `PackSize row saved`;
        }
        
        await updateWorkflow(workflow!);
    };

    useEffect(() => {
        const allRows = workflow!.rows.filter(x => !x.packSize && (!x.pack || !x.size || !x.uom));
        setRowCount(allRows.length);
        if (allRows.length) {
            setCurrentRow(allRows[0]);
            setValidPackSize(false);
        }else{
            setCurrentRow(undefined);
            setValidPackSize(true);
        }
    }, [workflow]);
    
    const moveToHigherQ = async () => {
        workflow!.level = 1;
        await updateWorkflow(workflow!)
    }

    if (!workflow) {
        return <div/>
    }

    return <div style={{padding: 5, backgroundColor: Colors.lightGrey, minHeight: 0, overflowY: "auto"}}>
        <div style={packSizeGridStyle}>
            <div style={{textAlign: 'center'}}>
                Pack
            </div>
            <div style={{textAlign: 'center'}}>
                Size
            </div>
            <div style={{textAlign: 'center'}}>
                Uom
            </div>
            <div style={{textAlign: 'center'}}>
                CW
            </div>
        </div>
        {currentRow &&
        <>
            <PackSizeEntryRow row={currentRow} key={'packSize' + currentRow.rowNumber} changed={() => setValidPackSize(currentRow.isValidPackSizeData())}/>
            <div style={{marginTop: 20, textAlign: "center"}}>
                <SaveButton disabled={!validPackSize || isSaving} onClick={saveWorkflow}>
                    {!validPackSize && "All PackSize data needs to be filled in"}
                    {(validPackSize || rowCount === 0) && (rowCount > 1 ? "Get Next" : "Save Workflow")}
                </SaveButton>
            </div>

            {stageLevel == 0 && <div style={{marginTop: 20, textAlign: "center"}}>
                <DangerButton onClick={moveToHigherQ}>Unable to Determine</DangerButton>
            </div>}
        </>
        }
        {!currentRow && workflow && rowCount === 0 && <div style={{marginTop: 20, textAlign: "center"}}>
            <div>No rows to process, everything done.</div>
            <SaveButton onClick={saveWorkflow} disabled={isSaving}>{isSaving ? 'Saving' : 'Save'} Workflow</SaveButton>
        </div>}

    </div>
}

function PackSizeEntryRow(props: { row: WorkflowRow, changed: () => void }) {
    const {row, changed} = props;
    const {getRowInfo, workflow} = useContext(WorkflowContext);
    const [desc, _setDesc] = useState(row.desc);
    const setDesc = (s: string) => {
        _setDesc(s);
        row.desc = s;
    }
    useEffect(() => {
        _setDesc(row.desc);
        if(!row.desc) {
            getRowInfo(workflow!.supplierId!, row.itemCode!, row.quantity, row.total).then(x => {
                row.desc = row.desc || x.description;
                _setDesc(existingDesc => existingDesc || x.description);
            })
        }
    }, [row, getRowInfo, workflow]);

    return <div style={{marginTop: 10}}>
        {!desc && <div>Missing Description, please provide:
            <input type="text" placeholder="Item Desc" value={desc} onChange={x => setDesc(x.target.value)} style={{
                backgroundColor: !desc ? Colors.danger : '',
                color: !desc ? Colors.dangerText : '',
            }}/>
        </div>}
        <PackSizeFields row={row} key={"packsizerow" + row.rowNumber} changed={changed} shouldAutoFocus={true}/>
    </div>
}